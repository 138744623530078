import {
  CORPORATE_FITNESS_PERMISSION_NAME,
  EGYM_PERMISSION_NAME,
  PUSH_NOTIFICATIONS_PERMISSION_NAME,
  RFID_PERMISSION_NAME,
  RFID_REVERSE_PERMISSION_NAME,
} from '@constants';

export const groupPermissions = (permissions: string[]) => {
  return [
    {
      key: 'egym',
      title: 'common.label.egym',
      items: [
        {
          key: 'syncEgymMember',
          title: 'locations.label.apiPermissions.syncEgymMember',
          value: permissions.includes(EGYM_PERMISSION_NAME),
        },
      ],
    },
    {
      key: 'rfid',
      title: 'locations.label.rfid',
      items: [
        {
          key: 'syncRfid',
          title: 'locations.label.apiPermissions.syncRfid',
          value: permissions.includes(RFID_PERMISSION_NAME),
        },
        ...(permissions.includes(RFID_PERMISSION_NAME)
          ? [
              {
                key: 'reverseRfid',
                title: 'locations.label.apiPermissions.reverseRfid',
                value: permissions.includes(RFID_REVERSE_PERMISSION_NAME),
              },
            ]
          : []),
      ],
    },
    {
      key: 'wellpass',
      title: 'locations.label.wellpass',
      items: [
        {
          key: 'syncMembers',
          title: 'locations.label.apiPermissions.syncWellpassMember',
          value: permissions.includes(CORPORATE_FITNESS_PERMISSION_NAME),
        },
      ],
    },
    {
      key: 'pushNotifications',
      title: 'locations.label.pushNotifications',
      items: [
        {
          key: 'sendPushNotifications',
          title: 'locations.label.apiPermissions.sendPushNotifications',
          value: permissions.includes(PUSH_NOTIFICATIONS_PERMISSION_NAME),
        },
      ],
    },
  ];
};
