import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
  getConnectedLocationApiPerformanceSummary,
  getConnectedLocationDetails,
  getConnectedLocationsPage,
  getConnectedLocationsStats,
} from '@api';
import { gymListTable } from '@globalState';
import { ApiPerformanceSummaryDateFilter, ApiVersion } from '@types';
import { useErrorHandlers } from '../useErrorHandlers';
import { configsKeys } from '../usePartnerConfigs';

export const connectedLocationsKeys = {
  all: (configId: number) => [...configsKeys.current(configId), 'locations'] as const,
  stats: (configId: number) => [...connectedLocationsKeys.all(configId), 'stats'] as const,
  page: (configId: number, filters: any) => [...connectedLocationsKeys.all(configId), 'page', { filters }] as const,
  details: (configId: number, id: number, version: ApiVersion) =>
    [...connectedLocationsKeys.all(configId), id, 'version', version] as const,
  performanceSummary: (configId: number, id: number, dateFilter: ApiPerformanceSummaryDateFilter) =>
    [...connectedLocationsKeys.all(configId), 'apiSummary', id, 'dateFilter', dateFilter] as const,
};

export const useConnectedLocations = (partnerConfigId: number = -1) => {
  const { onForbiddenError } = useErrorHandlers();
  const connectedLocationsStatsQuery = useQuery(
    connectedLocationsKeys.stats(partnerConfigId),
    async () => getConnectedLocationsStats({ queryParams: { partnerConfigId } }),
    {
      select: result => result.data,
      enabled: partnerConfigId !== -1,
      keepPreviousData: true,
      refetchOnMount: false,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );

  const tableQueryParams = useRecoilValue(gymListTable.selectors.queryParams);
  const connectedLocationsQuery = useQuery(
    connectedLocationsKeys.page(partnerConfigId, tableQueryParams),
    async () =>
      getConnectedLocationsPage({
        queryParams: { ...tableQueryParams, partnerConfigId },
      }),
    {
      select: result => result.data,
      enabled: partnerConfigId !== -1,
      keepPreviousData: true,
      refetchOnMount: false,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );

  return {
    connectedLocationsQuery,
    connectedLocationsStatsQuery,
  };
};

export const useConnectedLocationDetails = (apiVersion: ApiVersion, id: number = -1, partnerConfigId: number = -1) => {
  const { onForbiddenError } = useErrorHandlers();
  const connectedLocationDetailsQuery = useQuery(
    connectedLocationsKeys.details(partnerConfigId, id, apiVersion),
    async () =>
      getConnectedLocationDetails({
        urlParams: { id },
        queryParams: { partnerConfigId, apiVersion },
      }),
    {
      select: ({ data }) => data,
      enabled: partnerConfigId !== -1 && id !== -1,
      keepPreviousData: true,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );

  return {
    connectedLocationDetailsQuery,
  };
};

export const useConnectedLocationPerformanceSummary = (
  apiVersion: ApiVersion,
  id: number = -1,
  partnerConfigId: number = -1,
  dateFilter: ApiPerformanceSummaryDateFilter = ApiPerformanceSummaryDateFilter.lastDay,
) => {
  const { onForbiddenError } = useErrorHandlers();
  const connectedLocationPerformanceSummaryQuery = useQuery(
    connectedLocationsKeys.performanceSummary(partnerConfigId, id, dateFilter),
    async () =>
      getConnectedLocationApiPerformanceSummary({
        urlParams: { id },
        queryParams: { partnerConfigId, dateFilter },
      }),
    {
      select: ({ data }) => data,
      enabled: apiVersion === ApiVersion.v2 && partnerConfigId !== -1 && id !== -1,
      keepPreviousData: true,
      onError: ({ response }) => {
        if (response?.status === 403) {
          onForbiddenError();
        }
      },
    },
  );
  return {
    connectedLocationPerformanceSummaryQuery,
  };
};
