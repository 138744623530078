export enum ApiVersion {
  v1 = 'V1',
  v2 = 'V2',
}

export type ConnectionLocationAddress = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  country?: string;
};

export type ConnectedLocationItem = {
  id: number;
  apiVersion: ApiVersion;
  locationId: string;
  locationLegacyId: number;
  name: string;
  chainName: string;
  locationAddress?: ConnectionLocationAddress;
  active: boolean;
  lastActive?: string;
  totalApiCalls?: number;
  successApiCalls?: number;
  failedApiCalls?: number;
};

export type ConnectedLocationsStats = {
  active: number;
  total: number;
};

export type ConnectedLocationDetails = {
  id: number;
  apiVersion: ApiVersion;
  apiKey: string;
  locationId: string;
  locationLegacyId: number;
  name: string;
  chainName: string;
  locationAddress?: ConnectionLocationAddress;
  active: boolean;
  imageId?: string;
  permissions: string[];
};

export enum ApiPerformanceSummaryDateFilter {
  lastDay = 'LAST_DAY',
  last3Days = 'LAST_3_DAYS',
  lastWeek = 'LAST_WEEK',
}

export type ApiPerformanceSummaryChartDataItem = {
  time: string;
  rate: number;
};

export type ApiPerformanceSummaryFailedCallDetails = {
  count: number;
  method?: string;
  apiTemplate?: string;
  statusCode: number;
  errorMessage?: string;
};

export type ApiPerformanceSummary = {
  totalApiCalls: number;
  successApiCalls: number;
  chartData: ApiPerformanceSummaryChartDataItem[];
  failedCalls: ApiPerformanceSummaryFailedCallDetails[];
};
